import VirtualShowRoom from '@/components/virtual-showroom';
import CardSection from '@/ctf-components/section/card-section';
import Awards from '../awards/awards';
import ContactForm from '../contact-form';
import Jobs from '../jobs';
import PersonBanner from '../person-banner';
import { Quote } from '../quote/Quote';
import { SliderSection } from './SliderSection';
import ArticleSection from './article-section';
import PersonBannerSection from './person-banner-section';

const Section = ({ section, slug, count, bannerData }: any) => {
  switch (section.__typename) {
    case 'ArticleSection':
      return <ArticleSection section={section} />;
    case 'Quote':
      return <Quote section={section} />;
    case 'CardSection':
      return <CardSection slug={slug} section={section} />;
    case 'PersonBanner':
      return <PersonBanner data={section} sectionbaner={false} />;
    case 'PersonBannerSection':
      return <PersonBannerSection section={section} />;
    case 'SliderSection':
      return <SliderSection section={section} />;
    case 'AwardsAndCertificates':
      return <Awards section={section} />;
    case 'ContentFlag':
      if (section.internalTitle === 'Virtual Showroom') {
        return <VirtualShowRoom />;
      } else if (section.internalTitle === 'Jobs API') {
        return <Jobs count={count} bannerData={bannerData} />;
      } else if (
        section.internalTitle === 'Contact form' ||
        section.internalTitle === 'Incyber Contact form'
      ) {
        return (
          <ContactForm
            recipient={
              section.isConsulteerPage ? 'sales@consulteer.com' : undefined
            }
            data={section}
          />
        );
      } else return <></>;
    default:
      return null;
  }
};

export default Section;
